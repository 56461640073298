
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  computed: {
    contact_button() {
      return this.$store.getters['globals/contact_button']
    },
  },

  methods: {
    scrollTo() {
      const search = '[scroll-to-slug="configurator"]'
      const component = document.querySelector(search)
      if (!component) {
        return
      }
      component.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      })
    },

    scrollyScrollTo() {
      const search = '[scroll-to-id="0"]'
      const component = document.querySelector(search)
      if (!component) {
        return
      }
      component.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      })
    },
  },
}
